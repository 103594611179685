.nameHeader {
    color: #000000;
    font-size: 54px;
    top: 181px;
    left: 583px;
    position: absolute;
}

.jobTitleHeader {
    color: #5aa8d9;
    font-size: 34px;
    top: 261px;
    left: 583px;
    position: absolute;
}

.profileDesc {
    color: #000000;
    font-size: 24px;
    top: 341px;
    left: 583px;
    position: absolute;
    margin-right: 25px;
}

.header {
    font-size: 220%;
    color: #753960;
    display: flex;
    flex-direction: row;
}

.header:before, .header:after{
    color: black;
    content: "";
    flex: 1 1;
    border-bottom: 6px solid;
    margin: auto;
}

.header:before {
    margin-left: 24px;
    margin-right: 35px;
}

.header:after {
    margin-right: 24px;
    margin-left: 35px;
}

.tab {
    margin-left: 15px;
}

.fa {
    height:150px;
    width:150px;
    cursor:pointer;
    top:16px;
    padding: 0;
    border: none;
    position: absolute;
    text-align: center;
    font-size: 50px;
    text-decoration: none;
}

.fa:hover {
    opacity: 0.7;
}

.fa-linkedin {
    background: #86BAB7;
    color: white;
    left:523px;
}

.fa-instagram {
    background: #753960;
    color: white;
    left:823px;
}

.fa-email {
    background: #6891A6;
    color: white;
    left:673px;
}

.fa-twitter {
    background: #AB6F6F;
    color: white;
    left:973px;
}

.fa-facebook {
    background: #C99381;
    color: white;
    left:1123px;
}

.fa-spotify {
    background: #F4CDA8;
    color: white;
    left:1273px;
}

body {
    background-color: #cccccc;
    font-family: Helvetica, sans-sarif;
    color: black;
}

button {
    height:150px;
    width:150px;
    cursor:pointer;
    padding: 0;
    border: none;
    position: absolute;
}